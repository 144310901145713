import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36f1719a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "entity-list-top page-container py-6"
}
const _hoisted_2 = ["aria-labelledby"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslationAlt = _resolveComponent("TranslationAlt")!
  const _component_Translation = _resolveComponent("Translation")!
  const _component_Label = _resolveComponent("Label")!

  return (_ctx.entities.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (entity, index) => {
          return (_openBlock(), _createElementBlock("section", {
            class: "entity-list-top-content",
            key: entity['@id'],
            "aria-labelledby": 'entity-list-top-content-heading-' + index
          }, [
            _createVNode(_component_Label, { size: "bg" }, {
              title: _withCtx(() => [
                _createElementVNode("h1", {
                  id: 'entity-list-top-content-heading-' + index
                }, [
                  _createVNode(_component_TranslationAlt, {
                    data: entity,
                    property: "title"
                  }, null, 8, ["data"])
                ], 8, _hoisted_3)
              ]),
              sublabel: _withCtx(() => [
                _createVNode(_component_Translation, {
                  data: entity,
                  property: "description"
                }, null, 8, ["data"])
              ]),
              _: 2
            }, 1024)
          ], 8, _hoisted_2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}