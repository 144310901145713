import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30d0473a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "acquisitions-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityListTopContent = _resolveComponent("EntityListTopContent")!
  const _component_EntityList = _resolveComponent("EntityList")!
  const _component_SpinnerContainer = _resolveComponent("SpinnerContainer")!

  return (!_ctx.loading && !_ctx.loadingPageContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_EntityListTopContent, { entities: _ctx.entitiesContent }, null, 8, ["entities"]),
        _createVNode(_component_EntityList, {
          entities: _ctx.entities,
          label: _ctx.$t('acquisition.list')
        }, null, 8, ["entities", "label"])
      ]))
    : (_openBlock(), _createBlock(_component_SpinnerContainer, { key: 1 }))
}