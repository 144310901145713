
import { defineComponent } from "vue";
import EntityList from "../../entity/EntityList.vue";
import EntityListTopContent from "../../entity/EntityListTopContent.vue";
import SpinnerContainer from "../../../shared/components/SpinnerContainer.vue";
import { useAcquisitionsResource, useApiResource, useDocumentTitle } from "@kunsten/core";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "Acquisitions",
    components: { EntityList, EntityListTopContent, SpinnerContainer },
    setup() {
        const { t } = useI18n();

        useDocumentTitle(t("general.base-title"), t("navigation.acquisitions"));

        const { entities: entitiesContent, loading: loadingPageContent } = useApiResource("/api/acquisition_pages", {
            page: "1",
            pagination: "false",
            "order[sortNumber]": "asc",
        });

        const { loading, entities } = useAcquisitionsResource({
            page: "1",
            pagination: "false",
            "order[sortNumber]": "asc",
        });

        return { loading, entities, entitiesContent, loadingPageContent };
    },
});
