
import { defineComponent } from "vue";
import Label from "../../shared/components/Label.vue";
import Translation from "../../shared/components/Translation.vue";
import TranslationAlt from "../../shared/components/TranslationAlt.vue";

export default defineComponent({
    components: { Label, Translation, TranslationAlt },
    props: {
        entities: {
            type: Array,
            default: () => [],
        },
    },
});
